
import { apiWalletLogList } from '@/api/channel_provider'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import DatePicker from '@/components/date-picker.vue'
import { RequestPaging } from '@/utils/util'
@Component({
  components: {
      lsPagination,
      ExportData,
      LsDialog,
      DatePicker
  }
})
export default class ShopList extends Vue {
  /** S Data **/

  apiWalletLogLists = apiWalletLogList;

  pager: RequestPaging = new RequestPaging();

  recordData = []
  searchObj: any = {
    cid: '',
      remark: '',
      start_time: '',
      end_time: ''
  };
  /** E Data **/

  /** S Methods **/

  // 获取商城列表
  async getLists() {
      this.pager.request({
          callback: apiWalletLogList,
          params: {
              ...this.searchObj,
              cid: this.$route.query.id,
          }
      })
  }

  // 重置搜索
  reset(): void {
      Object.keys(this.searchObj).map((key) => {
          if (key === 'cid') return
          this.$set(this.searchObj, key, '')
      })
      this.getLists()
  }


  /** E Methods **/

  /** S ods **/
  created() {
      this.searchObj.cid = this.$route.query.id
      this.getLists()
  }
}
