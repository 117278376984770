import request from '@/plugins/axios'

/** S 渠道商列表 **/
// 渠道商列表
export const apiChannelProviderLists = (params: any) => request.get('/shop.channel_provider/lists', {params})

// 添加渠道商
export const apiChannelProviderAdd = (params: any) => request.post('/shop.channel_provider/add', params)

// 渠道商详情
export const apiChannelProviderDetail = (params: any) => request.get('/shop.channel_provider/detail', {params})

// 编辑渠道商
export const apiChannelProviderEdit = (params: any) => request.post('/shop.channel_provider/edit', params)

// 删除渠道商
export const apiChannelProviderDelete = (params: any) => request.post('/shop.channel_provider/delete', params)

// 修改超级管理员
export const apiChannelProviderChangeSuperAdmin = (params: any) => request.post('/shop.channel_provider/changeSuperAdmin', params)

// 修改渠道商状态
export const apiChannelProviderSwitchStatus = (params: any) => request.post('/shop.channel_provider/switchStatus', params)

// 历史套餐
export const apisetMealLogLists = (params: any) => request.get('/shop.channel_provider/setMealLogLists', {params})


export const apiSetMealLists = (params: any) => request.get('/shop.set_meal/lists', {params})



/** S 渠道商等级 **/
// 获取渠道商等级列表
export const apiChannelProvideGradeList = (params: any) =>
    request.get('/channel_provider.grade/lists', { params })


// 新增渠道商等级
export const apiChannelProvideGradeAdd = (data:any) =>request.post('/channel_provider.grade/add', data)

// 获取渠道商等级详情
export const apiChannelProvideGradeDetail = (
    params:any
)=> request.get('/channel_provider.grade/detail', { params })

// 编辑渠道商等级
export const apiChannelProvideGradeEdit = (data:any) =>
    request.post('/channel_provider.grade/edit', data)


// 删除渠道商等级
export const apiChannelProvideGradeDel = (data:any) =>
    request.post('/channel_provider.grade/del', data)


    // 充值
export const apiRechargeBalance = (data:any) =>
request.post('/shop.channel_provider/recharge', data)


export const apiWalletLogList = (params: any) =>
    request.get('/shop.channel_provider/wallet_lists', { params })

export const apiSupplierList = (params: any) =>
    request.get('/shop.channel_provider/supplier_lists', { params })

export const apiSetSupplierStatus = (params: any) =>
    request.post('/shop.channel_provider/set_supplier_status', params)